<template>
  <router-view />
</template>
<script lang="ts" setup>

</script>
<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
}

::-webkit-scrollbar {
  height: 0;
  width: 0;
}
</style>
