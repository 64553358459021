import { createStore } from 'vuex'

export default createStore({
  state: {
    name: 'xdd'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
