
<template>
  <!--地图容器-->
  <div id="container"></div>
</template>
   
<script lang="ts" setup>
import { onMounted } from 'vue'
import { shallowRef } from '@vue/reactivity' //引入
import AMapLoader from '@amap/amap-jsapi-loader'; //引入
let map: any = null;

const initMap = () => {
  AMapLoader.load({
    key: "4d82e9481e78dc52cbcd50c94506a61b", //此处填入我们注册账号后获取的Key
    version: "2.0", //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    plugins: [''], //需要使用的的插件列表，如比例尺'AMap.Scale'等
  }).then((AMap) => {
    let markWidth = 328;
    let markHeight = 155;
    let markY = -50
    if(window.innerWidth < 800) {
      markWidth = 164;
      markHeight = 77;
      markY = -30
    }
    // 设置一个图标对象
    var icon = {
      // 图标类型，现阶段只支持 image 类型
      type: 'image',
      // 图片 url
      image: require('@/assets/images/company-address.png'),
      // 图片尺寸
      size: [markWidth, markHeight],
      // 图片相对 position 的锚点，默认为 bottom-center
      anchor: 'top-left',
      offset: new AMap.Pixel(10, markY) // 以 icon 的 [center bottom] 为原点
    };
    var LabelsData = [
      {
        name: '公司位置',
        position: [121.441293, 31.194402],
        zooms: [10, 20],
        opacity: 1,
        zIndex: 10,
        fold: true,
        icon
      },
    ];
    var map = new AMap.Map('container', {
      zoom: 15.8,
      center: [121.434275, 31.195254],
      showIndoorMap: false,
      zoomEnable: false,
      dragEnable: false,
    });

    var markers: any = [];
    var layer = new AMap.LabelsLayer({
      zooms: [3, 20],
      zIndex: 1000
    });
    layer.add(markers);
    // 图层添加到地图
    map.add(layer);

    // 初始化 labelMarker
    for (var i = 0; i < LabelsData.length; i++) {
      var curData: any = LabelsData[i];
      curData.extData = {
        index: i,
      };

      var labelMarker = new AMap.LabelMarker(curData);

      markers.push(labelMarker);
    }
    // 将 marker 添加到图层
    layer.add(markers);

    map.setFitView(null, false, [100, 150, 10, 10]);




  }).catch(e => {
    console.log(e);
  })
}

onMounted(() => {
  initMap()
})

</script>
   
<style scoped lang="less">
#container {
  width: 100%;
  height: 100%;
}
</style>