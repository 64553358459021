import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import HomeView from "@/views/HomeView.vue";

const isMobile = !!document.querySelector("html")?.style.fontSize;
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      hideSide: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
