<template>
  <div class="privacy-policy">
    <div class="content">
      <h3>凝聚元界官方网站个人信息保护政策</h3>
      <p>尊敬的用户：</p>
      <p>欢迎您访问凝聚元界官方网站（
        www.njyjxr.com，以下简称“官方网站”或“官网”）。凝聚元界官方网站由上海凝聚元界信息科技有限公司及其关联方（以下简称“凝聚元界”或“我们”）运营。我们深知个人信息对您的重要性，并将竭尽全力保护您的个人信息安全。我们将按国家法律法规要求，恪守权责一致、目的明确、选择同意、最少够用、公开透明、确保安全、主体参与的原则，保护您的个人信息。为了帮助您了解我们通过官方网站收集了您的哪些个人信息，以及您在使用我们官方网站时，我们如何使用、保存、共享和转让这些信息，我们特制定《凝聚元界官方网站个人信息保护政策》（以下简称“本政策”）。请您仔细阅读本政策尤其是粗体显示的内容。如您有任何疑问、意见或建议，您可通过本政策第十一节的联系方式与我们联系。
      </p>
      <div class="seg"></div>
      <p>
        本政策与您拟使用的凝聚元界官方网站息息相关，希望您在使用前仔细阅读并确认您已经充分理解本政策所写明的内容，按照本政策的指引做出您认为适当的选择。您使用或在我们更新本政策后（您可不时查看相关条款）继续使用凝聚元界的官方网站，即意味着您同意本政策(含更新版本)内容，并且同意凝聚元界按照本隐私政策收集、使用、保存和共享您的相关信息。
      </p>
      <p>本政策将帮助您了解以下内容：</p>
      <p>一、 本政策适用范围</p>
      <p>二、 我们如何收集和使用您的个人信息</p>
      <p>三、 征得授权同意的例外</p>
      <p>四、 我们如何使用Cookie和同类技术</p>
      <p>五、 我们如何存储您的个人信息</p>
      <p>六、 我们如何共享、转让、公开披露您的个人信息</p>
      <p>七、 我们如何保护您的个人信息</p>
      <p>八、 您的权利</p>
      <p>九、 未成年人个人信息保护</p>
      <p>十、 本政策的变更和修订</p>
      <p>十一、 如何联系我们</p>
      <p>十二、 本政策的生效</p>
      <div class="seg"></div>
      <p>一、 本政策适用范围</p>
      <p>
        本政策适用于我们通过凝聚元界向您提供的服务。需要特别说明的是，本政策不适用于其他第三方向您提供的服务。例如您通过凝聚元界链接到的第三方服务或网站。您理解这些服务由第三方独立向您提供，第三方将依照其政策或用户协议单独对您的个人信息处理承担责任。
      </p>
      <div class="seg"></div>
      <p>我们可能针对特定线上或线下的服务或产品制定具体服务或产品个人信息保护政策、声明、通知等（以下简称“具体政策”）。在您使用其他具体服务或产品时，请以该等具体政策为准。</p>
      <div class="seg"></div>
      <p>二、 我们如何收集和使用您的个人信息</p>
      <p>“个人信息”是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。</p>
      <div class="seg"></div>
      <p>“个人敏感信息”是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。</p>
      <div class="seg"></div>
      <p>在您使用官方网站时，我们会按照如下方式收集和使用提供相关服务所必需的个人信息；如果您不使用该项功能，我们将不会收集对应的信息。</p>
      <div class="seg"></div>
      <p>1. 我们如何收集您的个人信息</p>
      <p>(1) 浏览官方网站</p>
      <p>当您通过计算机或移动设备浏览官方网站时，我们将收集您的如下信息：</p>
      <p>a)
        技术信息，例如互联网协议（IP）地址、浏览器类型和版本、时区设置和位置、浏览器插件类型和版本、操作系统和平台、访问时间、IP地域、链接来源、入口页面、搜索词、访问标识码、访问时长、访问页数、最后停留页面、上一次访问时间、访问类型（是否首次访问）、访问频次、操作系统、网络服务商、语言环境、屏幕分辨率、
        屏幕颜色（比特）、Flash版本、是否支持Cookie、是否支持JAVA、打开时间、停留时长、停留页面地址以及您在设备上使用的用于访问官方网站的其他技术。</p>
      <p>b) 使用信息，例如有关您如何使用我们的网站、产品和服务的信息。</p>
      <p>(2) 注册账户和购买产品</p>
      <p>当您在我们的网站上购买产品时，您需要在凝聚元界网站上注册账户才能开始购物。为了与您联系并建立交易关系，帮助您实现网上购物，您需要提供您的姓名、公司、电话、邮箱、城市、行业、欲购产品数量和购买用途。</p>
      <p>(3) 交付产品或服务</p>
      <p>
        当您与我们建立交易关系后，我们将与我们合作的第三方配送公司（包括顺丰、圆通等）为您完成订单的交付。您知晓并同意我们及第三方配送公司会在上述环节内收集和使用您的姓名、手机号、地址，使您订购的商品能够安全送达。请您注意，我们的配送员在为您提供配送服务时，可能基于法律要求，要求您提供实名认证信息。我们会通过国家有权认证机构的专有设备对您的身份证件信息进行核验，这些信息会传送至监管机构的后台系统，我们并没有收集您的身份证件信息，我们的配送员须遵守公司保密制度的规定，对可能接触到的您的信息予以保密。
      </p>
      <p>(4) 售后服务</p>
      <p>为了向您提供售后服务，我们的售后客服人员可能会使用您在购买产品时提供给我们的用户信息以及与您的订单有关的信息。为了保证您的信息安全，我们的客服人员可能会使用您的用户信息核验您的身份。</p>
      <p>我们可能收集您在与我们的客服人员沟通时提供的其他信息，例如：您要求变更的配送地址、联系人或联系电话。</p>
      <p>(5) 申请使用我们的产品和服务</p>
      <p>当您在我们的网站上申请试用我们的产品和服务时，为了向您提供试用的产品和服务，了解您的需求，您需要提供您的姓名、企业或者机构、电话、邮箱、所在地和行业信息。</p>
      <p>(6) 保障产品或服务的安全</p>
      <p>我们可能通过您的浏览信息、订单信息判断您的账户风险，记录一些我们认为有风险的链接（“URL”）。收集这些信息，有助于我们及时排查风险，确保产品或服务的安全性。</p>
      <div class="seg"></div>
      <p>2. 我们如何使用您的个人信息</p>
      <p>（1） 我们会根据本政策的约定，在实现本网站对应功能的目的范围内使用您的个人信息。</p>
      <p>（2）
        请您注意，我们还收集、使用和共享汇总数据，例如统计数据或结构数据。汇总数据可能来自您的个人信息，但不是个人信息，因为它不会直接或间接显示您的身份，我们有权以任何合法目的对其进行使用（例如：我们可能会汇总您的使用情况信息，以找出有多少用户访问特定网站功能、研究消费者和市场偏好、改进现有产品和服务、财务预测和建模）如果我们将汇总数据与您的个人信息合并或关联，使之能够直接或间接识别您，我们会将合并后的信息视为个人信息，并将按照本声明中的规定处理。
      </p>
      <p>（3） 如果我们需要将您的个人信息用于本政策未载明的其它用途时，或基于特定目的收集而来的信息需要用于其他目的时，我们会通过您主动做出勾选的方式事先征得您的同意。</p>
      <div class="seg"></div>
      <p>三、 征得授权同意的例外</p>
      <p>根据相关法律法规规定，以下情形中收集和使用您的个人信息无需征得您的授权同意：</p>
      <div class="seg"></div>
      <p>与我们履行法律法规规定的义务相关的；</p>
      <p>与国家安全、国防安全直接有关的；</p>
      <p>与公共安全、公共卫生、重大公共利益直接相关的；</p>
      <p>与犯罪侦查、起诉、审判和判决执行等直接有关的；</p>
      <p>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到您本人授权同意的；</p>
      <p>所收集的个人信息是您自行向社会公众公开的；</p>
      <p>根据您要求签订和履行合同所必需的；</p>
      <p>从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
      <p>用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；</p>
      <p>法律、法规或国家标准规定的其他情形。</p>
      <div class="seg"></div>
      <p>四、 我们如何使用 Cookie和同类技术</p>
      <p>
        为确保产品正常运转，我们会在您的计算机或移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助于Cookie，官方网站能够存储您的偏好等数据。我们不会将Cookie用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookie。有关详情，请参见AboutCookies.org。您可以清除计算机上保存的所有Cookie，大部分网络浏览器都设有阻止Cookie的功能。但如果您这么做，则需要在每一次访问我们的官方网站时亲自更改用户设置。如需详细了解如何更改浏览器设置，请访问以下链接：
        Internet Explorer、 Google Chrome、 Mozilla Firefox、 Safari、 Opera。
      </p>
      <div class="seg"></div>
      <p>
        除Cookie外，们还可能会在网站上使用网Beacon等其他同类技术。我们的网页上通常会包含一些电子图像（称为“单像素”GIF文件或“网络Beacon”）。我们会通过网络Beacon计算用户的访问数量并通过访问Cookie辨认注册的凝聚元界用户。我们还会通过我们得到的Cookies信息，为您提供个性化服务。
      </p>
      <div class="seg"></div>
      <p>五、 我们如何存储您的个人信息</p>
      <p>（一） 存储地点</p>
      <p>
        我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。如果法律法规有明确规定，或者我们获得了您的明确授权，我们会在履行法律法规规定的义务后，向境外实体提供您的个人信息。我们会通过合同等形式确保境外接收方以不低于本政策规定的程度保护您的个人信息。
      </p>
      <p>（二） 存储期限</p>
      <p>我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：</p>
      <div class="seg"></div>
      <p>完成您同意使用的业务功能；</p>
      <p>保证我们为您提供服务的安全和质量；</p>
      <p>您同意的更长的留存期间；</p>
      <p>法律法规是否要求或规定更长的期限，例如：由于法律规定了诉讼时效期间，为了应对未来可能发生的纠纷，我们可能需要在此期间内留存一些基本的信息作为未来可能使用的证据材料。</p>
      <p>在超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。</p>
      <div class="seg"></div>
      <p>如我们停止运营官方网站及相关服务，我们将及时停止继续收集您个人信息的活动，并将以公告的形式进行停止运营通知。同时，我们将对此前存储的个人信息进行删除或匿名化处理。</p>
      <div class="seg"></div>
      <p>六、我们如何共享、转让、公开披露您的个人信息</p>
      <p>（一） 共享</p>
      <p>我们不会与凝聚元界以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</p>
      <p>在获取您明示同意的情况下，我们会与其他方共享您的个人信息。</p>
      <p>我们可能会根据法律法规规定、诉讼争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。</p>
      <p>在法律法规允许的范围内，为维护我们、我们的关联公司或合作伙伴、您或其他凝聚元界用户或社会公众利益、财产或安全免遭损害而有必要共享您的个人信息。
        与我们的关联公司共享。为便于我们向您提供服务，我们可能会将您的个人信息与我们的关联公司共享。但我们只会共享必要的个人信息，且关联公司对您个人信息的使用受本政策，或经过您授权同意的且对您的个人信息提供与本政策保护水平实质相同的关联公司政策的约束。我们和我们的关联公司均会严格遵守凝聚元界的个人信息及数据安全保护制度和政策。
      </p>
      <p>为了向您提供产品和服务，我们可能与我们的合作伙伴共享您的订单信息、账户信息。我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型：</p>
      <p>商品或技术服务的供应商。我们可能会将您的个人信息共享给支持凝聚元界功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是实现网站的购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号和订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等；</p>
      <p>第三方商家。如果您通过第三方商家购买我们的产品或服务，我们必须将您的订单信息等与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</p>
      <p>委托我们进行推广的合作伙伴。一些情况下，我们会代表其他企业向使用我们的产品或服务的用户群提供促销推广的服务。我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托凝聚元界进行推广的合作伙伴（“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们会将这些信息进行汇总，以便它不会识别到您个人。比如我们可能告知该委托方有多少用户查看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</p>
      <p>对于我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本政策以及其他任何相关的保密和安全措施来处理个人信息。为了保障数据在第三方安全可控，我们还推出了云鼎服务，在云端提供安全可靠的数据使用和存储环境，确保用户数据的安全性。在个人敏感数据使用上，凝聚元界要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。</p>
      <p>为了遵守法律、执行或适用凝聚元界的使用条件和其他协议，或者为了保护凝聚元界、您或其他凝聚元界客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，我们可能与其他公司和组织交换信息。</p>
      <div class="seg"></div>
      <p>（二） 转让</p>
      <p>我们不会将您的个人信息转让给凝聚元界以外的其他任何公司、组织或个人，但以下情形除外：</p>
      <p>事先获得您明确的同意或授权；</p>
      <p>根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</p>
      <p>符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</p>
      <P>随着我们业务的发展，我们及我们的关联公司有可能进行合并、收购、资产转让或其他类似的交易。如相关交易涉及到您的个人信息转让，我们会要求新持有您个人信息的公司、组织和个人继续受此政策约束，否则我们将要求该公司、组织和个人重新征得您的授权同意。</P>
      <div class="seg"></div>
      <p>（三） 公开披露</p>
      <p>我们仅会在以下情况下，公开披露您的个人信息：</p>
      <p>获得您明确同意后；</p>
      <p>基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。在符合法律法规的前提下，当凝聚元界收到上述披露信息的请求时，我们会要求其必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求凝聚元界提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。</p>
      <div class="seg"></div>
      <p>七、我们如何保护您的个人信息</p>
      <p>我们非常重视您的个人信息安全，已采取符合业界标准的安全防护措施保护您提供的个人信息，以防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。</p>
      <p>凝聚元界设置了专门的个人信息安全责任人，负责处理凝聚元界相关产品和服务可能涉及到用户个人信息的各项事务，以及规划和制定公司的政策、审核各产品的用户使用协议、监督各产品的工作原理和信息处理机制等。</p>
      <div class="seg"></div>
      <p>我们的个人信息保护负责人邮箱是：hao.li@njyjxr.com</p>
      <p>我们通过建立专门的管理制度、流程、组织等管理措施以保障信息的安全。例如我们制定了信息安全工作的总体方针和安全策略，建立了各层面的安全管理制度，明确了安全管理机构内的各个部门和岗位的职责、分工和技能要求。</p>
      <p>我们的网络服务采取了传输层安全协议等加密技术，通过HTTPS等方式提供浏览服务，确保用户数据在传输过程中的安全性。我们采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。在展示个人信息、关联计算个人信息时，我们会采用包括内容替换、SHA256在内多种数据脱敏技术增强个人信息在使用中的安全性。</p>
      <div class="seg"></div>
      <p>我们在服务端部署访问控制机制，对可能接触到您个人信息的工作人员采取最小够用授权原则。我们采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</p>
      <div class="seg"></div>
      <p>我们会采用代码安全自动检查、数据访问日志分析技术对服务器操作进行账号审计及监控。如果发现外部公告有安全问题的服务器操作系统，我们会在第一时间进行服务器安全升级，确保所有服务器系统及应用安全。</p>
      <div class="seg"></div>
      <p>我们制定了网络安全事件应急预案，并调配足够的资源保障确保应急预案的执行。如果我们的物理、技术或管理防护措施不幸遭到破坏，我们会及时启动应急预案，防止安全事件扩大，按照法律法规的要求上报国家主管机关，并及时采取推送、公告等合理、有效的方式向您告知安全事件的基本情况、可能的影响、已经采取的措施或将要采取的措施等。</p>
      <div class="seg"></div>
      <p>尽管我们采取了以上措施，但请您知悉，互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，凝聚元界建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。互联网环境并非百分之百安全，我们将尽力确保或担保您发送给凝聚元界的任何信息的安全性。如果凝聚元界的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，凝聚元界将协助追究相关人员相应的法律责任。</p>
      <div class="seg"></div>
      <p>在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、凝聚元界已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。如果您对凝聚元界的个人信息保护有任何疑问，或者发现您在凝聚元界的个人信息泄密或遭到侵害，您可以通过本政策第十一部分的联系方式与我们取得联系。</p>
      <div class="seg"></div>
      <p>八、您的权利</p>
      <p>我们非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</p>
      <p>（一）访问和更正您的个人信息</p>
      <p>除法律法规规定外，您有权通过本政策第十一节提供的方式随时访问和更正您的收货信息、订单信息、浏览信息、档案信息以及您在使用凝聚元界的产品与/或服务过程中产生的其他个人信息。</p>
      <div class="seg"></div>
      <p>（二）删除您的个人信息</p>
      <p>以下情况发生时，您有权要求我们删除您的个人信息：</p>
      <p>我们没有征求您的同意，违法收集了您的个人信息。</p>
      <p>我们处理您的个人信息违反了法律法规要求。</p>
      <p>我们违反了与您的约定来使用和处理您的个人信息。</p>
      <p>您不再使用我们的产品或服务。</p>
      <p>我们停止对您提供服务。</p>
      <p>您可以通过本政策第十一节提供的方式联系我们要求删除您的个人信息，我们会在15个工作日内给予答复。如果我们决定响应您的删除请求，我们还将同时通知从凝聚元界获得您的个人信息的实体，要求其及时删除您的个人信息，除非法律法规另有规定，或这些实体获得您的独立授权。当我们从服务器中删除您的个人信息后，我们可能不会立即从备份系统中删除相应的数据，但会在备份更新的时候删除这些信息。</p>
      <div class="seg"></div>
      <p>（三）撤回您的授权同意</p>
      <p>您可以通过删除信息、在凝聚元界网站中进行隐私设置等方式（如有相关功能）改变您授权凝聚元界继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户或删除信息的方式，撤回凝聚元界继续收集您个人信息的全部授权。</p>
      <div class="seg"></div>
      <p>请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，凝聚元界无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。</p>
      <div class="seg"></div>
      <p>（四）注销您的账户</p>
      <p>您可以联系客服注销您的账户。您注销账户后，凝聚元界将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，凝聚元界将删除您的个人信息。</p>
      <div class="seg"></div>
      <p>（五）响应您的请求</p>
      <p>如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们的产品或服务时所产生的其他个人信息，或您认为凝聚元界存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以通过本政策第十一部分的联系方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或以其他方式证明您的身份，凝聚元界将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段、给他人合法权益带来风险或者非常不切实际（例如，涉及备份信息）的请求，凝聚元界可能会予以拒绝。</p>
      <div class="seg"></div>
      <p>在以下情形中，按照法律法规要求，凝聚元界将无法响应您的请求：</p>
      <p>（1） 与国家安全、国防安全有关的；</p>
      <p>（2） 与公共安全、公共卫生、重大公共利益有关的；</p>
      <p>（3） 与犯罪侦查、起诉和审判等有关的；</p>
      <p>（4） 有充分证据表明您存在主观恶意或滥用权利的；</p>
      <p>（5） 响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的。</p>
      <div class="seg"></div>
      <p>九、我们如何处理儿童的个人信息</p>
      <p>我们的产品、网站和服务主要面向成人。如果没有父母或监护人的同意，不满14周岁的儿童不得使用我们的产品或服务。</p>
      <div class="seg"></div>
      <p>对于经父母同意而收集儿童个人信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护儿童所必要的情况下使用或公开披露此信息。如果我们发现自己在未事先获得可证实的父母同意的情况下收集了儿童的个人信息，我们会与相关监护人协商，并设法尽快删除相关数据。</p>
      <div class="seg"></div>
      <p>十、本政策的变更和修订</p>
      <p>为了给您提供更好的服务以及随着凝聚元界业务的发展，本政策也会随之更新。但未经您明确同意，我们不会削减您依据本政策所应享有的权利。</p>
      <div class="seg"></div>
      <p>我们会通过在网站发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您不时访问我们的网站以便及时了解最新的隐私政策。对于本政策的重大变更，我们会提供显著的通知。您亦可以随时浏览官方网站查看最新的政策。</p>
      <div class="seg"></div>
      <p>本政策所指的重大变更包括但不限于：</p>
      <p>我们的服务模式发生重大变化。如处理个人信息的目的、处理个人信息的类型、个人信息的使用方式等；</p>
      <p>我们在控制权等方面发生重大变化。如并购重组等引起的所有者变更等；</p>
      <p>个人信息共享、转让或公开披露的主要对象发生变化；</p>
      <p>您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
      <p>我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
      <p>个人信息安全影响评估报告表明存在高风险时。</p>
      <p>您在此类变更和修订之后继续使用我们的网站，将被视为您同意本政策的变更和修订。</p>
      <div class="seg"></div>
      <p>十一、如何联系我们</p>
      <p class="weight700">上海凝聚元界信息科技有限公司 为凝聚元界官方网站的运营主体，亦是您个人信息的控制者，注册地址为上海市徐汇区乐山路33号5幢4楼。如果您对我们的政策及对您个人信息的处理有任何疑问、意见、建议或投诉，请发送邮件至hao.li@njyjxr.com与我们进行联系。您还可以随时通过访问微信公众号或拨打凝聚元界客服电话：021-54107208等多种方式与凝聚元界联系。</p>
      <div class="seg"></div>
      <p>在一般情况下，我们会在15个工作日内对您的请求予以答复。您理解，由于材料审核、业务核对、操作流程等原因，对您请求的处理完成时间可能会长于上述时限。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向我们所在地上海市网信、电信、公安及工商等监管部门进行投诉或举报；或向我们所在地（上海市）具有管辖权的法院提起诉讼。我们希望您在向政府或法院投诉或起诉前，可以与我们进行友好协商，欢迎并感谢您对我们的监督和建议。</p>
      <div class="seg"></div>
      <p>十二、本政策如何生效</p>
      <p>本政策制定于2023年<span class="weight700">【10】月【18】日</span>，将于2023年<span class="weight700">【10】月【18】日</span>正式生效。</p>
    </div>
  </div>
</template>
<script lang="ts" setup>
</script>
<style lang="less" scoped>
.privacy-policy {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #2c3e50;
  height: 100%;

  .content {
    width: 60%;
    max-width: 800px;
    background-color: #fff;
    height: 90%;
    border-radius: 4px;
    padding: 50px 80px;
    box-sizing: border-box;
    text-align: left;
    line-height: 2;
    font-size: 15px;
    overflow: hidden;
    overflow-y: auto;

    h3 {
      text-align: center;
      margin-bottom: 30px;
    }

    .seg {
      margin: 50px 0;
    }
  }
  .weight700 {
    font-weight: 700;
  }
  .color-red {
    color: red;
  }
}</style>