<template>
  <div class="home">
    <div class="title-list">
      <img @click="toTop" class="website-logo" src="@/assets/images/logo.png" />
      <div class="home-title-tabs">
        <div v-for="(item, index) in titleList" :key="index" :class="currentActiveIndex === index ? 'active' : ''"
          @click="changeTitle(index)">{{ item.label }}</div>
      </div>
    </div>
    <div class="banner-bg service">
      <img src="https://njyjxr.oss-cn-shanghai.aliyuncs.com/webimg/banner-bg.jpg" />
      <!-- <el-carousel height="100%" :pause-on-hover="false" :interval="10000">
        <el-carousel-item v-for="url in bannerList" :key="url">
          <img :src="url" />
        </el-carousel-item>
      </el-carousel> -->
    </div>
    <div class="service-more service_position">
      <div>
        <img src="@/assets/images/logo-text.png" />
        <div>打造数实融合的空间计算平台</div>
      </div>
      <img src="@/assets/images/reference-image.jpg" />
    </div>
    <div class="programme">
      <div class="programme-text1">解决方案</div>
      <div class="programme-text2">数实融合 虚实互生</div>
      <div class="programme-text2">共同推动产业创新融合发展</div>
      <div class="progress-bar-box">
        <div class="progress-bar">
          <div
            :style="{ width: 10.78 / programmeList.length * programmeIndex + 'vw', minWidth: 120 / programmeList.length * programmeIndex + 'px' }"
            class="active"></div>
        </div>
        <div @click="changeProgrammeIndex(-1)">
          <img v-if="programmeIndex === 1" src="@/assets/images/prev.png" />
          <img v-if="programmeIndex !== 1" src="@/assets/images/nextA.png" style="transform: rotate(180deg);" />
        </div>
        <div @click="changeProgrammeIndex(1)">
          <img v-if="programmeIndex == 3" src="@/assets/images/prev.png" style="transform: rotate(180deg);" />
          <img v-if="programmeIndex !== 3" src="@/assets/images/nextA.png" />
        </div>
      </div>
      <div class="programme-list-box">
        <div v-for="(item, index) in programmeList" :key="index" class="programme-list">
          <div class="programme-img">
            <img :src="item.url" />
          </div>
          <div class="programme-text-title">{{ item.title }}</div>
          <div>{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="cooperate">
      <div class="item-title">合作案例</div>
      <div class="item-note">为合作伙伴提供全方位支持</div>
      <div class="cooperate-view">
        <div v-for="(item, index) in cooperateList" :key="index" class="cooperate-row">
          <div v-for="(e, i) in item" :key="i">
            <img :src="e.url" />
            <div>{{ e.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="media_center">
      <div class="item-title">元宇宙行业动态</div>
      <div class="item-note">洞察行业最新动态，记录前行精彩瞬间</div>
      <div class="media-center-news">
        <div v-for="(item, index) in mediaCenterList" :key="index">
          <img :src="item.url" />
          <div class="media-news-box">
            <div class="media-title">{{ item.title }}</div>
            <div class="media-content">{{ item.content }}</div>
            <div class="media-annotation">{{ item.annotation }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_us">
      <div class="item-title">关于我们</div>
      <div class="item-note">元宇宙服务商</div>
      <div class="about-us-info">
        <div class="about-us-right">
          <map-container></map-container>
        </div>
        <div class="about-us-left">
          <div class="name">凝聚元界</div>
          <div>公司聚焦行业应用场景的打造，专注于元宇宙行业技术应用，搭建XR+AI技术应用平台，以技术平台为发展引擎，助推更多元宇宙场景落地。借助混空（Mixed
            Space)元宇宙空间搭建平台，人人皆可创作自己的元宇宙生活场景，为广阔无垠的元宇宙添砖加瓦。</div>
        </div>
      </div>
      <div class="about-us-honor">
        <img src="@/assets/images/about_us_2.jpg" />
      </div>
    </div>
    <div class="home-bottom">
      <div>
        <img class="logo-2" @click="toTop" src="@/assets/images/logo-white.png" />
        <div>
          <img class="QR-code" src="@/assets/images/QR_code.jpg" />
          <div class="QR-code-text">关注公众号&emsp;了解最新动态</div>
        </div>
      </div>
      <div>
        <div class="content-list">
          <div>
            <div class="font-style-1">产品服务</div>
            <div class="font-style-2" @click="changeTitle(0)">混空 MixedSpace</div>
          </div>
          <div>
            <div class="font-style-1">解决方案</div>
            <div class="font-style-2" @click="changeTitle(1, 1)">文娱解决方案</div>
            <div class="font-style-2" @click="changeTitle(1, 2)">工业解决方案</div>
            <div class="font-style-2" @click="changeTitle(1, 3)">教育解决方案</div>
          </div>
          <div>
            <div class="font-style-1">关于我们</div>
            <div class="font-style-2" @click="changeTitle(4)">公司介绍</div>
            <div class="font-style-2" @click="changeTitle(4)">行业动态</div>
            <div @click="router.push('/privacy-policy')" class="font-style-2">隐私协议</div>
          </div>
          <div>
            <div class="font-style-2" @click="changeTitle(2)">合作咨询</div>
            <div class="font-style-3">021-54107208</div>
            <div class="font-style-2">何先生</div>
            <div class="font-style-3">13225532650</div>
            <div class="font-style-2">杜先生</div>
            <div class="font-style-3">15316012553</div>
          </div>
        </div>
      </div>
      <div class="privacy">
        <div class="font-style-4">上海市徐汇区肇嘉浜路1065号飞雕国际大厦30-31层</div>
        <div class="font-style-4">
          <a href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2023012463号-1</a>
          <span> &emsp; 版权所有 © 2023上海凝聚元界信息科技有限公司 </span>
          <img class="police_insignia" src="@/assets/images/police_insignia.png" />
          <a href="https://beian.mps.gov.cn/#/query/webSearch"> 沪公网安备 31010402010108号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router';
import MapContainer from '@/components/MapContainer.vue'
import { titleList, programmeList, cooperateList, mediaCenterList } from '@/config'

const programmeIndex = ref(1)
const currentActiveIndex = ref(-1)
const router = useRouter()
const bannerList = ['https://njyjxr.oss-cn-shanghai.aliyuncs.com/webimg/banner-bg.jpg', 'https://njyjxr.oss-cn-shanghai.aliyuncs.com/webimg/banner-bg2.jpg']

const toTop = () => {
  window.scrollTo(0, 0)
  currentActiveIndex.value = -1
}

const changeTitle = (index: number, value?: number) => {
  currentActiveIndex.value = index;
  const offTops = titleList.map(e => Math.round((document.querySelector('.' + e.value) as any)?.offsetTop))
  window.scrollTo(0, offTops[index])

  // 定位方案
  if (value) {
    changeProgrammeIndex(-1, value)
  }
}

const changeProgrammeIndex = (symbol: number, value?: number) => {
  if ((symbol > 0 && programmeIndex.value < programmeList.length) || (symbol < 0 && programmeIndex.value > 1)) {
    programmeIndex.value += symbol
  }
  if (value) {
    programmeIndex.value = value
  }
  const programmeListDoms = document.getElementsByClassName('programme-list');
  const programmeScrollWidth = document.querySelector('.programme-list-box')?.scrollWidth || 0;
  let currentIndex = 0;
  Array.from(programmeListDoms).forEach((item: any, index: number) => {
    if (programmeIndex.value === (index + 1)) {
      currentIndex = index
    }
  })
  document.querySelector('.programme-list-box')?.scrollTo(programmeScrollWidth / programmeListDoms.length * currentIndex, 0)
}

onMounted(() => {
  const boxHeight = document.querySelector('.service')?.clientHeight
  const screenHeight = document.body.clientHeight;
  const programmeListDoms = document.getElementsByClassName('programme-list').length || 1;
  let offTops: any = null
  if (boxHeight === screenHeight) {
    document.querySelector('.service')?.classList.add('view-more')
  }
  changeSize()
  window.addEventListener('resize', function (event: any) {
    offTops = null;
    const boxHeight = document.querySelector('.service')?.clientHeight
    const screenHeight = document.body.clientHeight;
    if (boxHeight === screenHeight && getScrollTop() == 0) {
      document.querySelector('.service')?.classList.add('view-more')
    } else {
      document.querySelector('.service')?.classList.remove('view-more')
    }
  });
  window.addEventListener('scroll', function (event: any) {
    const boxHeight = document.querySelector('.service')?.clientHeight
    const screenHeight = document.body.clientHeight;
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const innerHeight = window.innerHeight
    const innerWidth = window.innerWidth
    if (scrollTop > 0) {
      document.querySelector('.service')?.classList.remove('view-more')
    } else if (boxHeight === screenHeight) {
      document.querySelector('.service')?.classList.add('view-more')
    }
    changeSize()

    // 页面定位
    if (!offTops) {
      offTops = titleList.map(e => Math.round((document.querySelector('.' + e.value) as any)?.offsetTop))
    }
    let currentIndex = 0
    let effectIndex = 0 // 当前动效的位置
    offTops.forEach((e: number, index: number) => {
      const hasDefault = document.querySelector('.' + titleList[index].value)?.classList.contains('default-dom')
      if (e <= scrollTop) {
        currentIndex = index;
      }
      if (!hasDefault) {
        // 显示出来的元素
        if (e > innerHeight && e < scrollTop + innerHeight - innerWidth * 0.09) {
          effectIndex = index;
        }
        // 默认显示的元素
        if ((scrollTop + innerHeight - innerWidth * 0.09) > e) {
          document.querySelector('.' + titleList[index].value)?.classList.add('default-dom')
        }
      }
    })
    if (scrollHeight - (scrollTop + screenHeight) < 20) {
      currentIndex = offTops.length - 1;
    } else if (scrollTop < offTops[0]) {
      currentIndex = -1
    }
    currentActiveIndex.value = currentIndex;

    const currentShow = document.querySelector('.' + titleList[effectIndex].value)?.classList.contains('show-dom')
    if (effectIndex && !currentShow) {
      document.querySelector('.' + titleList[effectIndex].value)?.classList.add('show-dom')
    }
  });

  document.querySelector('.programme-list-box')?.addEventListener('scroll', function (event: any) {
    const programmeScrollLeft = document.querySelector('.programme-list-box')?.scrollLeft || 0;
    const programmeClientWidth = document.querySelector('.programme-list-box')?.clientWidth || 0;
    const programmeScrollWidth = document.querySelector('.programme-list-box')?.scrollWidth || 0;
    programmeIndex.value = Math.round(programmeScrollLeft / (programmeScrollWidth / programmeListDoms)) + 1
    if (programmeScrollWidth - (programmeScrollLeft + programmeClientWidth) < 10) {
      programmeIndex.value = programmeList.length;
    }
  })

  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const innerWidth = window.innerWidth
  const innerHeight = window.innerHeight
  offTops = titleList.map(e => Math.round((document.querySelector('.' + e.value) as any)?.offsetTop))
  offTops.forEach((e: number, index: number) => {
    if ((scrollTop + innerHeight - innerWidth * 0.09) > e) {
      document.querySelector('.' + titleList[index].value)?.classList.add('default-dom')
    }
  })
})

const changeSize = () => {
  const bodyWidth = document.body.clientWidth
  // 22px缩放字体
  const px22 = Math.max(Math.round(bodyWidth / 100 * 1.15), 10)
  // 20px缩放字体
  const px20 = Math.max(Math.round(bodyWidth / 100 * 1.04), 9)
  // 14px缩放字体
  const px14 = Math.max(Math.round(bodyWidth / 100 * 0.73), 7)
  // 12px缩放字体
  const px12 = Math.max(Math.round(bodyWidth / 100 * 0.625), 6)
  // 12px缩放字体
  const px10 = Math.max(Math.round(bodyWidth / 100 * 0.52), 6)
  Array.from((document.getElementsByClassName('media-news-box') as any)).forEach((dom, index) => {
    if (px22 < 12) {
      (document.getElementsByClassName('media-title') as any)[index].style.transform = `scale(${px22 / 12})`;
      (document.getElementsByClassName('media-title') as any)[index].style.width = (28.2 / 100 * bodyWidth) * 12 / px22 + 'px';
      (document.getElementsByClassName('media-news-box') as any)[index].style.height = 12.87 / 100 * bodyWidth + 'px';
      (document.getElementsByClassName('media-content') as any)[index].style.lineHeight = 1.4
    }
    if (px14 < 12) {
      (document.getElementsByClassName('media-content') as any)[index].style.transform = `scale(${px14 / 12})`;
      (document.getElementsByClassName('media-content') as any)[index].style.width = (28.5 / 100 * bodyWidth) * 12 / px14 + 'px';
    }
    if (px12 < 12) {
      (document.getElementsByClassName('media-annotation') as any)[index].style.transform = `scale(${px12 / 12})`;
      (document.getElementsByClassName('media-annotation') as any)[index].style.width = (28.2 / 100 * bodyWidth) * 12 / px12 + 'px';
    }
  });
  Array.from((document.getElementsByClassName('font-style-1') as any)).forEach((dom: any) => {
    if (px20 < 12)
      dom.style.transform = `scale(${px20 / 12})`;
  });
  Array.from((document.getElementsByClassName('font-style-2') as any)).forEach((dom: any) => {
    if (px14 < 12) {
      dom.style.transform = `scale(${px14 / 12})`;
      (document.querySelector('.QR-code-text') as any).style.transform = `scale(${px14 / 12})`;
    }
  });
  Array.from((document.getElementsByClassName('font-style-3') as any)).forEach((dom: any) => {
    if (px14 < 12)
      dom.style.transform = `scale(${px14 / 12})`;
  });
  Array.from((document.getElementsByClassName('font-style-4') as any)).forEach((dom: any) => {
    if (px14 < 12)
      dom.style.transform = `scale(${px14 / 12})`;

  });
}

const getScrollTop = () => {
  let scrollTop = 0;
  if (document.documentElement && document.documentElement.scrollTop) {
    scrollTop = document.documentElement.scrollTop;
  } else if (document.body) {
    scrollTop = document.body.scrollTop
  }
  return scrollTop;
}
</script>

<style lang="less" scoped>
.home {
  position: relative;
}

.title-list {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.5vw;
  min-height: 52px;
  background: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  padding: 0 6.41vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  backdrop-filter: blur(30px);

  .website-logo {
    width: 7.087vw;
    min-width: 95px;
    cursor: pointer;
  }

  .home-title-tabs {
    width: 27.93vw;
    min-width: 416px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    text-align: center;

    &>div {
      position: relative;
      width: 60px;
      height: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      color: #464646;

      &.active {
        color: #000000;
      }

      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 30px;
        width: 0;
        height: 2px;
        background: #000000;
        transition: all 0.2s;
      }

      &.active::before {
        width: 36px;
        left: 12px;
      }
    }
  }
}

// .banner-bg>div {
//   width: 100%;
//   height: 100vh;
//   min-height: 45vw;
//   max-height: 56vw;

//   img {
//     width: 100%;
//     height: 100%;
//   }
// }
.banner-bg>img {
  width: 100%;
  height: 100vh;
  min-height: 45vw;
  max-height: 56vw;
}

.service-more {
  position: relative;
  margin-top: 32px;
  height: 41.9vw;
  background: linear-gradient(177deg, #C6CED6 0%, #E0E3E6 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 15.25%;
  padding-right: 15.8%;
  box-sizing: border-box;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url(~@/assets/images/service-more.png);
    background-size: 100% 100%;
  }

  &>img {
    width: 35.55vw;
  }

  &>div {
    height: 7.25vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    &>img {
      width: 23.4vw;
    }

    &>div {
      font-size: 1.565vw;
      font-family: AlibabaPuHuiTiR;
      color: #000000;
    }
  }
}

.service {
  font-size: 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 26px;
    margin-left: -17px;
    background: url(~@/assets/images/view-more.png);
    background-size: 100% 100%;
    width: 33px;
    height: 33px;
    transition: all 0.3s;
    opacity: 0;
  }
}

.service.view-more {
  position: relative;

  &::after {
    content: '';
    transition: all 0.2s;
    opacity: 1;
  }
}

.programme {
  padding-left: 15.06vw;
  padding-top: 7.97vw;

  .programme-text1 {
    font-size: 1.928vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 2.71vw;
    text-align: left;
    margin-bottom: 0.63vw;
  }

  .programme-text2 {
    width: 38vw;
    font-size: 3.022vw;
    text-align: left;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #333333;
    line-height: 4.22vw;
    background: linear-gradient(124deg, #143696 0%, #55546D 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .progress-bar-box {
    margin-top: 1.67vw;
    padding-right: 32vw;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0;

    .progress-bar {
      width: 10.78vw;
      min-width: 120px;
      height: 2px;
      background: #D7D7D7;
      margin-right: 24px;

      .active {
        height: 2px;
        background-color: #000;
        transition: all 0.5s;
      }
    }

    img {
      width: 1.82vw;
      min-width: 27px;
      margin-left: 7px;
      cursor: pointer;
    }
  }

  .programme-list-box {
    width: 100%;
    margin-top: 2.14vw;
    overflow: hidden;
    overflow-x: auto;
    font-size: 0;
    white-space: nowrap;

    &>div {
      width: 52.84vw;
      display: inline-block;
      margin-right: 1.82vw;
      border-radius: 0.625vw;
    }

    .programme-list {
      font-size: 1.1vw;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      text-align: left;
      margin-bottom: 2.6vw;

      &>div {
        width: 100%;
        white-space: pre-wrap;
      }

      .programme-img {
        width: 100%;
        height: 25.53vw;
        margin-bottom: 1.3vw;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .programme-text-title {
        margin-bottom: 0.62vw;

      }
    }
  }
}

.item-title {
  font-size: 3.44vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 4.8vw;
  margin-bottom: 0.63vw;
}

.item-note {
  font-size: 1.35vw;
  font-family: AlibabaPuHuiTiR;
  color: #999999;
  line-height: 1.82vw;
}

.cooperate {
  padding-top: 9vw;
}

.cooperate-view {
  width: 100%;
  box-sizing: border-box;
  padding: 0 11.57vw;
  margin-top: 6vw;
  margin-bottom: 1.7vw;
  overflow: hidden;

  .cooperate-row {
    width: 100%;
    height: 15.06vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.6vw;

    &>div {
      position: relative;
      border-radius: 0.625vw;
      width: 24.6vw;
      height: 15.06vw;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 5vw;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      }

      img {
        width: 100%;
        height: 100%;
      }

      div {
        position: absolute;
        left: 1.51vw;
        bottom: 1.09vw;
        font-size: 1.04vw;
        font-family: AlibabaPuHuiTiR;
        color: #FFFFFF;
        display: inline-block;
        text-align: left;
        margin-right: 1.04vw;
      }
    }
  }
}

.media_center {
  padding-left: 23.19vw;
  padding-right: 25vw;
  padding-top: 9vw;

  .media-center-news {
    margin-top: 4.38vw;

    &>div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0;
      padding: 2.6vw 0;
      border-bottom: 1px solid #B9B9B9;

      &:last-child {
        border-bottom: none;
      }

      img {
        width: 20.48vw;
        height: 12.87vw;
      }

      &>div {
        position: relative;
        width: 28.2vw;
        height: 12.87vw;
        font-size: 0.73vw;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        text-align: left;

        &>div {
          transform-origin: 0 0;
        }

        .media-title {
          font-size: 1.15vw;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-bottom: 0.94vw;
        }

        .media-content {
          line-height: 1.8;
        }

        .media-annotation {
          position: absolute;
          bottom: -5px;
          font-size: 0.625vw;
          margin-top: 1.98vw;
        }
      }
    }
  }
}

.about_us {
  margin-top: 5.36vw;
  padding-top: 9vw;
}

.about-us-info {
  position: relative;
  width: 91.82vw;
  height: 33.33vw;
  margin-left: 8.23vw;
  margin-top: 7.03vw;

  .about-us-right {
    position: relative;
    position: absolute;
    right: 0;
    top: 0;
    width: 50.03vw;
    height: 100%;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .about-us-left {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    box-sizing: border-box;
    width: 41.79vw;
    background: rgba(5, 25, 100, 0.9);
    font-size: 1.1vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #D3D3D3;
    padding-top: 5vw;
    padding-left: 4.74vw;
    padding-right: 2.55vw;
    line-height: 1.8;
    text-align: left;
    letter-spacing: 3px;


    .name {
      font-size: 3.02vw;
      color: #fff;
      margin-bottom: 2.52vw;
    }
  }
}

.about-us-honor {
  font-size: 0;
  text-align: center;
  margin-top: 11.62vw;
  margin-bottom: 9.85vw;

  img {
    width: 68.58vw;
  }
}

.about_us,
.media_center,
.cooperate,
.programme {
  opacity: 0;

  &>div {
    position: relative;
    top: 1000px;
  }
}

.default-dom {
  opacity: 1;

  &>div {
    top: 0;
  }
}

.show-dom {
  opacity: 1;
  transition: all 0.5s;

  &>div {
    transition: all 0.5s;
    top: 0;
  }
}

.home-bottom {
  position: relative;
  height: 24.5vw;
  min-height: 160px;
  background: #000000;
  padding: 7.34vw 16vw;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  .privacy {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    margin-bottom: 2.19vw;
    line-height: 1.6;
  }

  .logo-2 {
    width: 7.87vw;
    min-width: 116px;
    margin-bottom: 2.19vw;
    cursor: pointer;
  }

  .QR-code {
    width: 5vw;
    min-width: 50px;
  }

  .QR-code-text {
    font-size: 0.73vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888D9C;
    line-height: 14px;
    margin-top: 0.83vw;
  }

  .police_insignia {
    width: 1.04vw;
    min-width: 14px;
  }

  .content-list {
    display: flex;
    justify-content: space-between;
    width: 40vw;
    text-align: left;
  }

  // transform-origin: 0 0;
  .font-style-1 {
    font-size: 1.04vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 1.56vw;
    transform-origin: 0 0;
  }

  .font-style-2 {
    font-size: 0.73vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888D9C;
    margin-bottom: 0.94vw;
    cursor: pointer;
    transform-origin: 0 0;
  }

  .font-style-3 {
    font-size: 0.73vw;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
    margin-top: -0.7vw;
    margin-bottom: 1.2vw;
    transform-origin: 0 0;
  }

  .font-style-4 {
    font-size: 0.73vw;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #888D9C;

    span {
      vertical-align: middle;
    }

    img {
      vertical-align: middle;
    }

    &>a {
      color: #888D9C !important;
    }
  }
}

.el-carousel__item div {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
</style>