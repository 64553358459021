// 页面类目列表
export const titleList = [
  {
    label: '产品服务',
    value: 'service_position'
  },
  {
    label: '解决方案',
    value: 'programme'
  },
  {
    label: '合作案例',
    value: 'cooperate'
  },
  {
    label: '行业动态',
    value: 'media_center'
  },
  {
    label: '关于我们',
    value: 'about_us'
  }
]

// 解决方案相关文案
export const programmeList = [
  {
    title: '文娱元宇宙',
    content: '将元宇宙与内容资源融合创新，应用虚拟现实和新媒体技术打造沉浸式的时空新体验，实现“文化+科技”“文旅+消费”的新业态模式，推动文娱产业升级。',
    url: require('@/assets/images/entertainment.png')
  },
  {
    title: '工业元宇宙',
    content: '使用XR技术模拟复杂机械设备的装配、调试、维修维护等专业操作培训，提高员工培训效率，降低操作失误率，减少安全风险，为企业降本增效。',
    url: require('@/assets/images/industry.png')
  },
  {
    title: '教育元宇宙',
    content: '针对教育行业课程创建教学场景和虚拟仿真课件，实现教学内容的场景化和教学质量的闭环管理，突出互动性和趣味性，达到高效、有趣、安全的教学效果。',
    url: require('@/assets/images/education.png')
  }
]

// 合作案列文案
export const cooperateList = [
  [
    {
      name: '华东师范大学元宇宙与数智人实验室建设项目',
      url: require('@/assets/images/cooperate_1.jpg')
    },
    {
      name: '江苏信息职业技术学院创意设计学院虚拟绘画教学系统项目',
      url: require('@/assets/images/cooperate_2.jpg')
    },
    {
      name: '中能郝家桥数字体验馆建设项目',
      url: require('@/assets/images/cooperate_3.jpg')
    },
  ],
  [
    {
      name: '博物馆智能导览',
      url: require('@/assets/images/cooperate_4.jpg')
    },
    {
      name: '企业文化展厅',
      url: require('@/assets/images/cooperate_5.jpg')
    },
    {
      name: '德莎（苏州）胶带技术有限公司 EHS安全培训定制开发项目',
      url: require('@/assets/images/cooperate_6.jpg')
    }
  ]
]

// 行业动态文案
export const mediaCenterList = [
  {
    title: '元宇宙产业有了“三年行动计划”',
    content: '眼下，“元宇宙”成为热词，相关企业争相布局。近日，工业和信息化部等五部门印发《元宇宙产业创新发展三年行动计划（2023－2025年）》（以下简称“《行动计划》”），提出通过构建先进元宇宙技术和产业体系等举措，在政策层面为元宇宙产业发展指明方向，推动元宇宙产业发展。',
    annotation: '来源：数字中国建设峰会 | 2023年10月11日',
    url: require('@/assets/images/media_center_2.png')
  },
  {
    title: '上海首批元宇宙应用场景成果照进现实',
    content: '在东方明珠体验高空奇境VR过山车，“身临其境”走进古画里的世界；游览“山海奇豫”，和神兽一起逛灯会过中秋……元宇宙已成为上海四大新赛道之一。去年10月，上海发出20个元宇宙重大应用场景“英雄帖”，吸引了800余家技术与解决方案提供方竞相“揭榜”。经过近一年建设，上海市经信委正式发布的10家上海市元宇宙重大应用场景已基本建成。',
    annotation: '来源：人民网研究院 | 2023年09月26日',
    url: require('@/assets/images/media_center_3.png')
  },
  {
    title: '20多家元宇宙企业，共商张江数字孪生“未来之城”',
    content: '9月12日，张江数字孪生“未来之城”主题闭门沙龙在张江科学会堂举行。本次活动由张江集团主办，张通社承办，20余位企业代表参与闭门沙龙，围绕打造张江数字孪生未来之城展开了热切讨论。活动旨在为各家企业提供一个展示企业产品与实力的窗口，促使更多优质元宇宙企业加入到张江数字孪生未来之城的建设中来。',
    annotation: '来源：张通社 | 2023年09月14日',
    url: require('@/assets/images/media_center_1.png')
  }
]