import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// 引入矢量图标字体
import '@/assets/font/iconfont.css'
import zhCn from "element-plus/es/locale/lang/zh-cn";


const app = createApp(App)

app.use(ElementPlus, { locale: zhCn })
app.use(store)
app.use(router)
app.mount('#app')

// createApp(App).use(store).use(router).mount('#app')


// 解决长度自适应时候的报错问题
const debounce = (fn: any, delay: number, ...arg: any) => {
    let timer: any = null;
    return function () {
      const context = self;
      const args = arg;
      clearTimeout(timer);
      timer = setTimeout(function () {
        fn.apply(context, args);
      }, delay);
    }
  }
  
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
    constructor(callback: any) {
      callback = debounce(callback, 16);
      super(callback);
    }
  }
  